.container-partner-dasboard {
  display: flex;
  margin: 3vw;
  flex-direction: column;
}
.card-item {
  box-sizing: border-box;
  width: 22vw;
  height: 12vw;
  background: #ffffff;
  border: 1px solid #000000;
}
.card-header1 {
  color: white;
  background-color: orange;
}
.card-header3 {
  color: white;
  background-color: #86bc25;
}
.card-header2 {
  color: white;
  background-color: #0076a8;
}
.card-header4 {
  color: white;
  background-color: #26890d;
}
.card-section {
  margin-bottom: 2vw;
}
.filter-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 0;
}
.partner-act-table {
  margin: 1vw;
  padding: 1%;
  width: 100vw;
  border: 1px solid #d0d0ce;
}
.part {
  display: flex;
  align-items: center;
  padding: 1%;
}
.part-1 {
  flex-grow: 2;
}

.part-2 {
  font-weight: 700;
  flex-grow: 2;
}

.part-3 {
  flex-grow: 2;
}

.part-4 {
  color: #ed8b00;
  flex-grow: 2;
}
.part-5 {
  text-decoration-line: underline;
  color: #0076a8;
  flex-grow: 0;
  cursor: pointer;
}
.filter-section2 {
  // display: flex;
  // flex-direction: row;
  // justify-content: ;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 0;
}
