@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

#CompHead {
  @include displaySettings2(321px, 52px, 90px, 84px);
  @include fontSettings(700, 40px, 52px, #1a202c);
}

#compNavFirst {
  margin-left: 30px;
}

.compNav1 {
  @include displaySettings2(73px, 20px, 8px, 141px);
  @include fontSettings(400, 14px, 20px, #26890d);
  margin-right: 8px;
}

#compNav2 {
  @include displaySettings2(108px, 20px, 8px, 141px);
  @include fontSettings(400, 14px, 20px, #000000);
}

#repSent {
  @include displaySettings2(681px, 36px, 52px, 50px);
  @include fontSettings(700, 24px, 36px, #000000);
}

#retHome {
  @include displaySettings2(274px, 40px, 0px, 0px);
  @include fontSettings(400, 14px, 20px, #ffffff);
  background: #26890d;
  margin-left: 52px;
  margin-top: 20px;
  border: none;
}
