@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

@mixin flexSettings($display, $align-items, $flex, $order, $flex-grow) {
  display: $display;
  align-items: $align-items;
  flex: $flex;
  order: $order;
  flex-grow: $flex-grow;
}

.partnerManagerFlexColumn {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
}

.partnerManagerFlexRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.partnerManagerPdf {
  @include displaySettings2(320px, 27px, 22px, 0px);
  display: flex;
  flex-direction: row;
}

.partnerManagerpdfIcon {
  @include fontSettings(400, 24px, 24px, #53565a);
  @include displaySettings2(24px, 24px, 0px, 0px);
  font-family: "dds-icon-sm";
  font-style: normal;
  align-self: flex-start;
}

.partnerManagerpdfName {
  @include fontSettings(400, 14px, 20px, #53565a);
  @include displaySettings2(232px, 20px, 32px, 0px);
  @include flexSettings(flex, center, none, 0, 0);
  flex-direction: row;
  margin-top: -25px;
}

#partnerManagerDWIcon {
  @include displaySettings2(10px, 10px, 5px, 0px);
  display: flex;
  flex-direction: column;
  margin-top: -19px;
}

.partnerManagerdw1 {
  @include displaySettings2(9.42px, 12.4px, 250px, 0px);
  background: (0, 0, 0, 0);
}

.partnerManagerdw2 {
  @include displaySettings2(15.98px, 4.01px, 247px, 0px);
  background: (0, 0, 0, 0);
}

.partnerManagerEmp {
  @include displaySettings2(132px, 33px, 35px, 30px);
  @include fontSettings(400, 14px, 100%, #53565a);
}

.time {
  display: inline;
  word-wrap: break-word;
  color: #bbbcbc;
}

.partnerManagerDesc {
  @include displaySettings2(254px, 36px, 22px, 40px);
}

.partnerManagerComments {
  margin-top: 34px;
}

#partnerManagerReqAssigned {
  @include displaySettings2(327px, 20px, 30px, 0px);
  @include fontSettings(400, 14px, 20px, #53565a);
}

.partnerManagerDesc {
  @include displaySettings2(254px, 36px, 30px, 0px);
  @include fontSettings(400, 14px, 20px, #53565a);
}

#sentClient {
  @include displaySettings2(254px, 18px, 22px, 4px);
  @include fontSettings(700, 12px, 150%, #007cb0);
}

#sendCmnts {
  @include displaySettings2(300px, 28px, 20px, 0px);
  margin-top: 32px;
}

#sendCmntsBtn {
  @include displaySettings2(137px, 33px, 8px, 0px);
  background-color: #26890d;
  color: #ffffff;
  border: none;
}

#peerHist {
  @include displaySettings2(232px, 24px, 0px, 20px);
  @include fontSettings(600, 16px, 24px, #75787b);
}

.partnerManagerEmp3 {
  @include displaySettings2(132px, 33px, 35px, 60px);
  @include fontSettings(400, 14px, 100%, #53565a);
}

#partnerManagerFirstComp {
  margin-top: 46px;
}

.partnerManagerComp {
  @include displaySettings2(330px, 84px, 0px, 7px);
  @include fontSettings(400, 14px, 20px, #000000);
}

.partnerManagerComments3 {
  margin-top: 0px;
}

#ipField {
  margin-top: 75px;
}
