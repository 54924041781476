.dashboard-content-data {
  width: 68.75vw;
  height: 67.45vh;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.8vw;
}
.dashboard-content-row{
  display:flex;
  column-gap:1.5vw;
}
