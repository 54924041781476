.surveyQuest {
  font-weight: 600;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 1vw;
  line-height: 100%;
}

.survey-qa {
  margin-bottom: 2%;
}
.survey-frame {
  box-sizing: border-box;
  width: auto;
  height: auto;
  margin-top: 2%;
  background: #ffffff;
  border: 1px solid #d0d0ce;
  padding: 1vw;
}
.hed-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.09vw;
  line-height: 100%;
  color: #53565a;
}
.surveyAns {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 100%;
  color: #53565a;
}
