@mixin diplaySettings($gap) {
  display: flex;
  gap: $gap;
  align-items: center;
  justify-content: flex-start;
}

.technology-index {
  background: #f5f5f5;
  height: 36px;
  padding-left: 16px;
  @include diplaySettings(9px);
}

.technology-index-objects {
  @include diplaySettings(22px);
}

.technology-index-objects-values {
  @include diplaySettings(5px);
}
