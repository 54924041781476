@mixin displaySettings2($width, $height, $margin-left, $margin-top) {
  width: $width;
  height: $height;
  margin-left: $margin-left;
  margin-top: $margin-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

@mixin borderSettings($box-sizing, $background, $border) {
  box-sizing: $box-sizing;
  background: $background;
  border: $border;
}

#peerBigCirc1 {
  @include displaySettings2(1.25vw, 2.2vh, 3.75vw, 2.2vh);
}

#peerSmallCirc {
  @include displaySettings2(0.625vw, 1.1vh, 4.0625vw, 2.75vh);
  margin-top: -1.65vh;
}

#peerBigCirc2 {
  @include displaySettings2(1.25vw, 2.2vh, 3.75vw, 0px);
}

#peerVertLine {
  @include displaySettings2(auto, 5.1vh, 3.75vw, 0.55vh);
}

#peerEmp1 {
  @include displaySettings2(11.64vw, 4.53vh, 0.9375vw, 1.65vh);
}

#peerEmps {
  @include displaySettings2(11.64vw, 4.53vh, 0.9375vw, 3.45vh);
}

#peer3submit {
  @include displaySettings2(30.9375, 3.57vh, 3.9vw, 3.57vh);
  @include fontSettings(400, 24px, 36px, #000000);
}

#peer3submitdesc {
  @include displaySettings2(37.11vw, 8.24vh, 3.9vw, 3vh);
  @include fontSettings(700, 14px, 20px, #000000);
}

.comp-time {
  display: inline;
  word-wrap: break-word;
  color: #bbbcbc;
}

#peer3Req {
  @include displaySettings2(19.84vw, 5.5vh, 7.03125vw, 0vh);
}

#peer3ReqGreen {
  @include fontSettings(700, 1.1vw, 2.75vh, #26890d);
}

#peer3ReqGrey {
  @include fontSettings(700, 1.1vw, 2.75vh, #63666a);
}

#peer3review {
  @include displaySettings2(8.5vw, 3.3vh, 7vw, 3.57vh);
  background: rgba(0, 0, 0, 0.08);
  align-items: center;
}

#peer3reviewIcon {
  @include displaySettings2(1.1vw, 1.92vh, 0.74vw, 0px);
}

#peer3reviewText {
  @include displaySettings2(6.1vw, 2.2vh, 0.47vw, 0px);
  @include fontSettings(700, 0.9375vw, 2.2vh, #005478);
}

#peer3return {
  @include displaySettings2(21.4vw, 5.5vh, 3.9vw, 4.26vh);
  @include fontSettings(700, 1.1vw, 2.75vh, #ffffff);
  padding: 1.44vh 6.95vw;
  gap: 0.625vw;
  background: #26890d;
  border: none;
  cursor: pointer;
}

#peer3-vertLine {
  @include displaySettings2(0px, 6.456vh, 4.26vw, 43.27vh);
  border: 1.2px solid #0076a8;
  background-color: #0076a8;
}

#peer3LC {
  @include displaySettings2(70.3125, auto, 0px, 0px);
}

#peer3RC {
  margin-left: 30vw;
}
