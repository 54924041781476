@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.commFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#commPostBox {
  @include displaySettings2(1180px, 697px, 0px, 0px);
  margin-left: 50px;
  margin-top: 30px;
  border: 1px solid;
}

#commX {
  @include displaySettings2(auto, auto, 1127px, 17px);
}

#commPostHeading {
  @include displaySettings2(145px, 40px, 47px, 30px);
  @include fontSettings(600, 24px, 40px, #000000);
}

#commPostTypeHead {
  @include displaySettings2(215px, 16px, 47px, 30px);
  @include fontSettings(400, 16px, 16px, #000000);
}

#commPostGenActive {
  @include displaySettings2(55px, 20px, 47px, 10px);
  @include fontSettings(400, 14px, 20px, #000000);
}

#commPostSkSeSpActive {
  @include displaySettings2(103px, 20px, 8px, 10px);
  @include fontSettings(400, 14px, 20px, #000000);
}

#commPostSkSeSpDisabled {
  @include displaySettings2(103px, 20px, 8px, 10px);
  @include fontSettings(400, 14px, 20px, #000000);
  opacity: 0.5;
}

#commPostGenDisabled {
  @include displaySettings2(55px, 20px, 47px, 10px);
  @include fontSettings(400, 14px, 20px, #000000);
  opacity: 0.5;
}

.commPostSlct,
#commPostQry {
  @include displaySettings2(103px, 20px, 48px, 40px);
  @include fontSettings(400, 12px, 16px, #53565a);
}

#commPostTxtBtns {
  @include displaySettings2(752px, 39px, 0px, 0px);
  margin-left: 50px;
  margin-top: 12.47px;
  border: 1px solid #d0d0ce;
}

#commPostTxtBox {
  @include displaySettings2(754px, 209px, 0px, 0px);
  margin-left: 50px;
}

#commPostTxtBoxIP {
  @include displaySettings2(752px, 209px, 0px, 0px);
  border: 1px solid #d0d0ce;
}

#commPostBtnCncl {
  @include displaySettings2(76px, 40px, 0px, 0px);
  margin-left: 884px;
  margin-top: 167px;
  border: 1px solid #26890d;
  color: #26890d;
  opacity: 0.4;
}

#commPostBtnSbmt {
  @include displaySettings2(76px, 40px, 0px, 0px);
  margin-left: 37px;
  background-color: #26890d;
  color: #fff;
  opacity: 0.4;
}
