.legends-box {
  display: flex;
  justify-items: right;
  width: 100%;
}

.single-legends-box {
  display: flex;
  justify-items: right;
  align-items: center;
}

.legends-color {
  height: 0.5vw;
  width: 0.5vw;
  border-radius: 12px;
  margin-right: 1vw;
}
