@mixin displaySettings($direction, $alignItems, $gap) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  gap: $gap;
}

.news {
  background: #ffffff;
  border: 1px solid #d0d0ce;
  width:auto;
  height: auto;
  box-sizing: border-box;
  @include displaySettings(column, flex-start, none);
  padding-bottom: 2vh;
}

.news-header {
  padding: 1.5vh 0 0 0;
  width: 100%;
  @include displaySettings(row, none, none);
}

.news-header-text {
  padding: 0.82vh 0.78vw 2vh 1vw;
}

.news-arrow {
  margin-left: auto;
  padding: 0.55vh 1.72vw 1.8vh 0px;
}

.news-box-author {
  padding: 2vh 0 0 1vw;
  @include displaySettings(row, center, 0.4vw);
}

.news-box-data {
  padding: 1.2vh 0.625vw 0.85vh 1.65vh;
  @include displaySettings(row, flex-start, 0);
}

.news-box-data-text {
  margin-left: 1vw !important;
}

.news-box-data-image {
  width: 4.3vw;
  height: 6.32vh;
}

.news-box-data-timestamp {
  padding-left: 0.9375vw;
}
