.container {
  display: flex;
  margin: 3vw;
  flex-direction: row;
}
.login-about-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  // height: 100vh;
  background-color: white;
  gap: 1vw;
}

.login-input-container {
  width: 50vw;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 2vw;
}
.login-reginput-container {
  width: 50vw;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 2vw;
}
.login-input {
  flex-direction: column;
  display: flex;
  gap: 1vw;
  align-self: center;
}
.login-reg-input {
  flex-direction: column;
  display: flex;
  gap: 0.3vw;
  align-self: center;
}
.login-ext-image {
  width: 40vw;
  // height: 60vh;
  display: flex;
  align-items: flex-start;
}
.login-abt-sl {
  display: flex;
  gap: 1vw;
}
.login-abt-sl1 {
  background-color: green;
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
}
.login-abt-sl2 {
  background-color: grey;
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
}

.login-name-textfield {
  width: 25vw;
}
.login-ext-external {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 25vw;
  // height: 3vw;
  background: #26890d;
}
.login-ext-footer-img {
  width: 1vw;
}
.login-ext-arrow {
  width: 2vw;
  height: 2vw;
  font-family: "dds-icon-sm";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
}
.login-ext-register {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 25vw;
  height: 3vw;
  border: 1px solid #26890d;
  align-items: center;
}
.error-message {
  color: red;
}
.login-footer {
  display: flex;
  margin-top: 8vw;
  width: 25vw;
}
.forg-footer {
  display: flex;
  margin-top: 25vw;
  width: 25vw;
}
.reg-footer {
  display: flex;
  width: 25vw;
}
.reqack-footer {
  display: flex;
  width: 25vw;
  margin-top: 15vw;
}
.login-reqack-text1 {
  width: 25vw;
}
.login-fp-arrow {
  width: 2vw;
  height: 2vw;
  align-self: flex-end;
}
.success-logo{
  width: 5vw;
  margin: auto;
}