@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

#partnerManagerLabel {
  @include displaySettings2(1vw, 1.9vh, 0px, 0px);
  @include fontSettings(400, 1vw, 1vw, #86bc25);
  display: flex;
  flex-direction: row;
}

#partnerManagerExec {
  @include displaySettings2(4.29vw, 2.19vh, 0px, 0px);
  @include fontSettings(400, 0.93vw, 2.19vh, #86bc25);
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  order: 0;
  flex-grow: 0;
}

#PartnerManagerLabel2 {
  @include displaySettings2(1vw, 1.9vh, 448px, 12px);
  @include fontSettings(400, 1vw, 1vw, #007cb0);
  display: flex;
  flex-direction: row;
}

#partnerManagerSent {
  @include displaySettings2(5.29vw, 2.19vh, 3px, 12px);
  @include fontSettings(400, 0.93vw, 2.19vh, #007cb0);
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  order: 0;
  flex-grow: 0;
}

#partnerCompDownload {
  @include displaySettings2(16.4vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #ffffff);
  background: #26890d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  border: none;
  cursor: pointer;
}
#peerSetDownload{
  @include displaySettings2(17vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #ffffff);
  background: #26890d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  cursor: pointer;
}
#dwIcon {
  display: flex;
  flex-direction: column;
  margin-left: 38px;
}

#partnerCompDw1 {
  @include displaySettings2(0.73vw, 0.8vw, 0px, 0px);
}

#partnerCompDw2 {
  @include displaySettings2(0.9vw, 0.3vw, 0px, 0px);
}

#partnerCompLastUpdate {
  @include fontSettings(400, 0.93vw, 100%, #53565a);
  margin-top: 8px;
}
