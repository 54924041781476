//600-41-29
.font1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 3.2vw !important;
  line-height: 4vh !important;
  color: #1a202c;
  padding: 0.5vw;
}

//600-24-22
.font2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.9vw !important;
  line-height: 3vw !important;
  color: #000000;
}

//400-14-11
.font3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw !important;
  line-height: 1.5vw !important;
  color: #000000;
}
//400-10-7
.font4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8vw !important;
  line-height: 1vw !important;
  color: #000000;
}
//600-16.64-7.28
.font5 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3vw !important;
  line-height: 1vh !important;
  color: #000000;
  color: #75787b;
}

//400-14-15
.font6 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw !important;
  line-height: 3.06vh !important;
  color: #1a202c;
}
//400-12-16
.font7 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375vw !important;
  line-height: 1.3vw !important;
}
//400-14-20
.font8 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw !important;
  line-height: 1.5vw !important;
}
//600-24-16
.font9 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.875vw !important;
  line-height: 2.2vh !important;
  color: #000000;
}
//700-24-36
.font10 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.875vw !important;
  line-height: 4.95vh !important;
  color: #000000;
}
//400-16-24
.font11 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw !important;
  line-height: 3.3vh !important;
  color: #000000;
}
//700-14-20
.font12 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1vw !important;
  line-height: 2.75vh !important;
}

//600-24-35
.font13 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.875vw !important;
  line-height: 4.8vh !important;
  color: #000000;
}

.footer-font {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw !important;
  line-height: 0.9vw !important;
  color: #000000;
  opacity: 0.6;
}

.card-section {
  display: flex;
  justify-content: space-between;
}

//600-40-52
.header-font {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 3.125vw !important;
  line-height: 7.14vh !important;
}

.green-button-font {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw !important;
  line-height: 1.5vw !important;
  color: #26890d;
}

.card-section {
  display: flex;
  justify-content: space-between;
}

button {
  cursor: pointer;
}

