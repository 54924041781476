@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.left-content-details {
  display: flex;
  flex-direction: column;
  margin-top: 1vw;
  gap: 1vw;
}
.left-content-details-row {
  display: flex;
  flex-direction: row;
}
.left-content-details-item {
  @include fontSettings(400, 0.93vw, 1.3vw, #53565a);
  width: 25vw;
}
#partnerManagerLeftContent {
  @include displaySettings2(auto, auto, 2vw, 0px);
}

.label-text2 {
  font-size: 1.09vw !important;
  font-weight: 400;
  color: #000000 !important;
  @include fontSettings(400, 1.09vw, 1.3vw, #000000);
}

#uploadCompFiles {
  @include displaySettings2(25vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #26890d);
  margin-top: 67px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 2px solid #26890d;
}

#upIcon {
  display: flex;
  flex-direction: column;
  margin-left: 38px;
}

#up1 {
  @include displaySettings2(1vw, 0.8vw, 0px, 0px);
  rotate: 180deg;
}

#up2 {
  @include displaySettings2(1.2vw, 0.3vw, 0px, 0px);
}

#bottomBtns {
  display: flex;
  flex-direction: row;
  padding-left: 2vw;
  margin-bottom: 1vw;
}

#dashboard {
  @include displaySettings2(16.4vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #53565a);
  margin-top: 45px;
  background: white;
  border: 2px solid #53565a;
}

#declineReq {
  @include displaySettings2(16.4vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #da291c);
  margin-left: 30px;
  margin-top: 45px;
  background: white;
  border: 2px solid #da291c;
}

#approved {
  @include displaySettings2(16.4vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #53565a);
  margin-left: 30px;
  margin-top: 45px;
  background: #ffffff;
  border: 2px solid #53565a;
}

#approvedGreen {
  @include displaySettings2(16.4vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #ffffff);
  margin-left: 30px;
  margin-top: 45px;
  background: #26890d;
  border: 2px solid #26890d;
}

#genReport {
  @include displaySettings2(16.4vw, 3vw, 0px, 0px);
  @include fontSettings(400, 1.09vw, 2.7vh, #ffffff);
  margin-left: 30px;
  margin-top: 45px;
  background: #26890d;
  border: none;
}
