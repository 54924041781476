.mainReport-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mainReport-download-button {
  margin-left: auto;
}

.mainReport-text-data-block {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  flex-wrap: wrap;
}

.mainReport-text-data-header {
  display: flex;
  flex-direction: column;
  // gap: 35px;
  flex-wrap: wrap;
}

.mainReport-text-data-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;
  width: 775px;
}

.mainReport-text-data-bodywrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

.mainReport-text-data-image1 {
  height: 15px;
  width: 0px;
  border: 2px solid #0097a9;
}

.mainReport-text-data-image2 {
  width: 0;
  border-radius: 50%;
  border: 2px solid #000000;
  margin-top: 7px;
}
#commentSubmitButton {
  width: 6vw;
  height: 2vw;
  float: right;
  font-weight: 400;
  font-size: 1.09vw;
  line-height: 100%;
  color: #ffffff;
  background: #26890d;
  border: 2px solid #26890d;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 1vw !important;
  font-size: 1.09vw !important;
}
.MUIRichTextEditor-container-2 {
  font-size: 1vw !important;
}
