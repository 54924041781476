@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}
.first-content-desc {
  padding-top: 2.2vh;
  width: 68.7vw;
  height: 4.8vh;
  color: #53565a;
}

.displayHead {
  display: flex;
  flex-direction: row;
  gap: 1.17vw;
  align-items: center;
}

.backPageButtonCSS {
  margin-top: 2.75vh;
  width: 0.78vw;
  height: 1.37vh;
}
