.manager-persona-div {
  width: 100%;
}

.manager-persona-template-box {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #d0d0ce;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.mp-template-header {
  display: flex;
  flex-direction: column;
}

.mp-header-sub-section {
  display: flex;
  gap: 10px;
  margin-left: auto;
  align-items: center;
}

.all-reports-template-box {
  display: flex;
  flex-wrap: wrap;
  gap: 3vw;
  justify-content: space-between;
  margin-top: 1vw;
}

.single-report-box {
  width: 37.5vw;
  height: 16.09vw;
  border: 1px solid #d0d0ce;
  padding: 5px;
}

.single-report-header-box {
  padding: 0 18px;
}

.single-report-header {
  width: 90%;
  display: flex;
  align-items: center;
}

.single-report-download-image {
  width: 1.9vw;
  margin-left: auto;
}

.single-report-image-box {
  width: 100%;
  height: 12.5vw;
  cursor: pointer;
}
.single-checkbox {
  width: 1vw;
  height: 1vw;
  cursor: "pointer";
}
