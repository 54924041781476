@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

#partnerManagerCompBox {
  @include displaySettings2(90vw, auto, 0px, 0px);
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d0d0ce;
  // margin-left: 4vw;
  margin-top: 3vw;
}
.ln {
  margin: 2vw;
  background: rgba(255, 255, 255, 0.002);
  box-shadow: inset 0px -1px 0px #d0d0ce;
}
#partnerManagerCompHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2vw;
  padding-right: 2vw;
  margin-top: 1vw;
}

#partnerManagerCompLine {
  @include displaySettings2(86.72vw, 0.127vh, 0px, 0px);
  margin-left: 1.8vw;
  margin-top: 2.61vh;
  background: rgba(255, 255, 255, 0.002);
  box-shadow: inset 0px -1px 0px #d0d0ce;
}

#partnerManagerCompSummary {
  // @include displaySettings2(232px, 24px, 28px, 21px);
  @include fontSettings(600, 1.25vw, 3.29vh, #75787b);
}

#partnerManagerMainContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#smallCirc {
  width: 8px;
  height: 8px;
  margin-top: -12px;
  margin-left: 9px;
}

#bigCirc {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-left: 5px;
}

.vertLine {
  margin-top: 4px;
  z-index: 0;
}

#vertLine3 {
  height: 75px;
  margin-left: 5px;
}

#vertLine6 {
  height: 46px;
  margin-left: 4px;
}

#vertLine5 {
  height: 46px;
  margin-left: 4px;
}

.imgColumn {
  display: flex;
  flex-direction: column;
}

#flexCol3 {
  display: flex;
  flex-direction: column;
  margin-top: 125px;
  margin-left: 30px;
}

#flexCol6 {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: -180px;
}

#flexCol5 {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 100px;
}
