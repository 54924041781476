$color: #000000;

@mixin boxSettings($width, $height, $padding, $border) {
  width: $width;
  height: $height;
  padding: $padding;
  border: $border;
  box-sizing: border-box;
}

@mixin displaySettings($direction, $alignItems, $gap) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  gap: $gap;
}

.content-upload {
  @include boxSettings(29.5vw, auto, 4.39vh 2.5vw, 1px solid #bbbcbc);
  @include displaySettings(column, initial, 0.78vw);
  background: #ffffff;
}

.content-upload-header {
  align-self: stretch;
  color: $color;
}

.content-upload-desc {
  color: $color;
}

.content-upload-button {
  text-decoration-line: underline;
  color: #26890d;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.compensation-buttons {
  display: flex;
}

.comp-box-btns {
  margin-top: 6vh !important;
}
