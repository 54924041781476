@mixin displaySettings($direction, $gap, $alignment) {
  display: flex;
  flex-direction: $direction;
  gap: $gap;
  align-items: $alignment;
}

@mixin boxSettings($width, $height) {
  width: $width;
  height: $height;
}

.Onboard-form-setup {
  @include displaySettings(row, 50px, none);
}

.Onboard-form {
  box-sizing: border-box;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #d0d0ce;
  padding: 8px 39px;
  color: #000000;
  @include displaySettings(column, 0px, none);
  @include boxSettings(878px, 430px);
}

.onboard-form-label {
  color: #53565a;
  margin-top: 22px;
  margin-bottom: 5px;
}

.onboard-form-select {
  border: 1px solid #d0d0ce;
  background: #ffffff;
  box-sizing: border-box;
  @include boxSettings(358px, 32px);
  cursor: pointer;
}

.onboard-form-button {
  background: #26890d;
  padding: 9px 16px;
  gap: 8px;
  border: #26890d;
  align-items: center;
  margin-top: 46px;
  color: #ffffff;
  @include boxSettings(333px, 40px);
  cursor: pointer;
}

.companyLocation-filters {
  margin-top: 9px;
  color: #ffffff;
  @include displaySettings(row, 2px, flex-start);
  @include boxSettings(358px, 24px);
}

.companyLocation-filters-sub {
  padding: 3px 5px;
  background: #26890d;
  font-size: 12px;
  width: fit-content;
  @include displaySettings(row, 5px, flex-start);
}

.companyLocation-filters-sub-cross {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding: 2px 0 0 0;
  cursor: pointer;
}
