.manager-reports-section {
  border: 1px solid #d0d0ce;
  padding: 37px;
  display: flex;
  flex-direction: column;
}

.manager-one-reports-section {
  display: flex;
  flex-direction: row;
  gap: 49px;
  width: 100%;
  margin-bottom: 25px;
}

.manager-one-report-show {
  border: 1px solid #d0d0ce;
  width: 100%;
  padding: 10px;
}
