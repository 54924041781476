.mas-textfield {
  display: flex;
  flex-direction: column;
  p {
    padding: 0 1rem;
  }
}

.form-control {
  margin: 0px;
}

.MuiFormControl-root.form-control.css-15bjg8p {
  margin: 0px;
}

.mas-textlabel {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  color: #4a4a4a;
}
.css-78me8v-MuiFormControl-root {
  margin-top: 10px !important;
  margin: 0px !important;
}
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1.5vw !important;
  font-size: 1.1vw !important;
}
.error {
  height: 24px;
  font-family: "SourceSansProRegular";
  line-height: 24px;
  color: #4a4a4a;
  display: flex;
  margin: 4px 8px;
  color: #ff0000;
}

.mas-text-sub-label {
  position: static;
  left: 0%;
  right: 0%;
  top: 53.85%;
  bottom: 0%;
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #717171;
  flex: none;
  flex-grow: 0;
  margin: 4px 0 8px;
}

.disabled-input {
  background-color: #f2f2f2 !important;
}
