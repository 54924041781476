.main_container {
    // font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    // width: 87vw;
    gap:1.5vw;
    margin: 3vw;
  }
  .main_description {
    display: flex;
    flex-direction: column;
    color: "97999b" !important;
  }
  .main_form {
    display: flex;
    flex-direction: column;
  }