.tableContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
}
#download-file{
    width: fit-content;
    height: 3vw;
    font-weight: 400;
    font-size: 1.09vw;
    line-height: 2.7vh;
    color: #ffffff;
    background: #26890d;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    border: none;
    cursor: pointer;
}
#download-icon{
    display: flex;
    flex-direction: column;
}
