@import "./styles.scss";
@font-face {
  font-family: "SourceSansProRegular";
  src: local("SourceSansProRegular"),
    url("../fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansProBold";
  src: local("SourceSansProBold"),
    url("../fonts/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansProLight";
  src: local("SourceSansProLight"),
    url("../fonts/SourceSansPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansProSemiBold";
  src: local("SourceSansProSemiBold"),
    url("../fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SourceSansProItalic";
  src: local("SourceSansProItalic"),
    url("../fonts/SourceSansPro-Italic.ttf") format("truetype");
}

#root {
  min-height: 100%;
  min-width: 100%;
  overflow-x: hidden;
}

body {
  color: #4a4a4a;
  font-family: "SourceSansProRegular";
  margin:0;
  -webkit-font-smoothing: antialiased;
}

.mas-text-h1 {
  font-family: "SourceSansProSemiBold" !important;
  color: #1a202c;
  margin-top: 1.5vw;
  font-weight: 600;
  font-size: 3.1vw;
}

.mas-header-section {
  max-width: 74.5rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  // padding: 0 1rem;
}

.pagination {
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mas-is-sticky {
  position: fixed;
  top: 0px;
  z-index: 9999;
}

.loading {
  position: fixed;
  left: 50%;
  top: 50%;
  height: 53px;
  width: 53px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 4px solid #ebe8e4;
  border-right: 4px solid #ebe8e4;
  border-bottom: 4px solid #ebe8e4;
  border-top: 4px solid #268e6c;
  border-radius: 100%;
  &.modal-loader {
    left: 47%;
    top: 48%;
    height: 40px;
    width: 40px;
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
