.my-subscription-container {
  padding: 0 4rem;

  .mas-subscription-apiproduct {
    width: 1192px;
    height: 648px;
    top: 385px;
    left: 125px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    box-shadow: inset 0px -1px 0px #d3d3d3;
  }

  .mas-style-select {
    position: static;
    width: 265px;
    height: 40px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .MuiFormControl-root.css-b16mh4-MuiFormControl-root {
    margin: 8px 0px 0px 0px;
  }

  .mas-subs-textfield {
    width: 812px !important;
  }

  .clear-filter {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
  }

  .no-records {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
  }

  .loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .filter-section {
    // display: flex;
    // flex-direction: row;
    // justify-content: ;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0;
  }
}
.mas-subdetails-unsub-paragraph {
  line-height: 150% !important;
}

.manager-cnt1 {
  box-sizing: border-box;
  // display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px 13px;
  gap: 10px;
  width: 277px;
  height: 144px;
  left: 49px;
  top: 164px;
  background: #ffffff;
  border: 1px solid #000000;
}
.cnt1-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* White #FFFFFF */

  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 4px 3px;

  width: 223px;
  height: 20px;

  background: #ed8b00;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}
.cnt1-num {
  width: 245px;
  height: 65px;
  margin-top: 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;

  /* Black #000000 */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}
.manager-cnt2 {
  box-sizing: border-box;
  // display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px 13px;
  gap: 10px;
  width: 277px;
  height: 144px;
  left: 352px;
  top: 164px;
  background: #ffffff;
  border: 1px solid #bbbcbc;
}
.cnt2-name {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  align-items: flex-start;
  padding: 1px 4px 3px;
  width: 159px;
  height: 20px;
  background: #86bc25;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  align-items: center;

  color: #ffffff;
}

.cnt2-num {
  width: 245px;
  height: 114px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 100px;
  color: #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}
.manager-cnt3 {
  box-sizing: border-box;
  // display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px 13px;
  gap: 10px;
  width: 277px;
  height: 144px;
  left: 652px;
  top: 164px;
  background: #ffffff;

  border: 1px solid #bbbcbc;
}
.cnt3-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 4px 3px;

  width: 160px;
  height: 20px;

  background: #0076a8;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* White #FFFFFF */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}
.cnt3-num {
  width: 245px;
  height: 92px;
  margin-top: 18px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;

  /* Black #000000 */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.manager-cnt4 {
  box-sizing: border-box;
  // display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 16px 13px;
  gap: 10px;
  width: 277px;
  height: 144px;
  left: 954px;
  top: 164px;
  background: #ffffff;
  border: 1px solid #bbbcbc;
}

.cnt4-name {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 4px 3px;

  width: 160px;
  height: 20px;

  background: #26890d;

  /* Inside auto layout */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* White #FFFFFF */

  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.cnt4-num {
  width: 245px;
  height: 92px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 65px;
  margin-top: 18px;
  /* Black #000000 */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.manager-hdn1 {
  width: 530px;
  height: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.manager-hdn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 0px;
  gap: 10px;

  // position: absolute;
  width: 578px;
  height: 26px;
  left: 50px;
  top: 333px;
}
