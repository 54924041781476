@mixin displaySettings2($width, $height, $margin-left, $margin-top) {
  width: $width;
  height: $height;
  margin-left: $margin-left;
  margin-top: $margin-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

#ddsicon1 {
  @include displaySettings2(0.48vw, 1.3vh, 4.6875, 11.54vh);
}

#peer2box {
  @include displaySettings2(68.6vw, 37.77vh, 3.9vw, 6.32vh);
  background: #ffffff;
  border: 1px solid #d0d0ce;
  padding-bottom: 3vh;
}

#peer2GroupName {
  @include displaySettings2(8vw, 2.2vh, 2.5vw, 3.16vh);
  @include fontSettings(400, 12px, 16px, #53565a);
  display: inline-block;
}

#peer2GroupNameInp {
  @include displaySettings2(28vw, 4.4vh, 2.5vw, 0.68vh);
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #d0d0ce;
  align-self: stretch;
}

#peer2Search {
  @include displaySettings2(19.9vw, 2.2vh, 2.5vw, 3vh);
  @include fontSettings(400, 12px, 16px, #53565a);
  display: inline-block;
}

#peer2SearchInp {
  @include displaySettings2(28vw, 4.4vh, 2.5vw, 0.82vh);
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #d0d0ce;
  align-self: stretch;
}

#peerSearchData {
  @include displaySettings2(auto, auto, 1.5625vw, 1.1vh);
}

.peer2-data {
  @include displaySettings2(fit-content, 3.3vh, 0.9375vw, 1.1vh);
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.41vh 0.4vw;
  gap: 0.15625vw;
  background: #26890d;
  color: #ffffff;
}

#ddsicon2 {
  margin-left: 0.46875vw;
  margin-top: 0.68vh;
}

#createPeerBtn {
  @include displaySettings2(26vw, 5.75vh, 2.5vw, 3.16vh);
  background: #26890d;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

#peerGreenLine {
  @include displaySettings2(0.3125vw, 11vh, 0px, 1.23vh);
}

#peer2-one {
  @include fontSettings(600, 16px, 24px, #26890d);
  @include displaySettings2(auto, 3.3vh, 0.625vw, 0.8vh);
}

#peer2RChead {
  @include fontSettings(400, 14px, 20px, #000000);
  @include displaySettings2(15vw, 2.75vh, 0.625vw, 0.9375vh);
}

#peer2-para {
  @include fontSettings(400, 14px, 20px, #53565a);
  @include displaySettings2(auto, auto, 0.625vw, 1.92vh);
}

.companyName-filters {
  @include displaySettings2(auto, auto, 2.34vw, 0.55vh);
}

.compNameSelected {
  @include displaySettings2(fit-content, auto, 0.23vw, 1.1vh);
  @include fontSettings(400, 0.9375vw, 2.2vh, #ffffff);
  background-color: #26890d;
  display: inline;
  padding: 0.275vh 0.15vw;
  cursor: pointer;
  img {
    margin-left: 0.4vw;
  }
}
.compNameSelectedCross {
  width: 1.1vw;
  padding-top: 1vh;
}

#peer2RC {
  margin-left: 20vw;
}
