.doc-history{
    display: flex;
    flex-direction: column;
    gap:2vw;
    margin: 3vw;

}
.search-elem{
    display: flex;
    flex-direction: row;
    gap: 1vw;
}
.search-img{
    width: 1.5vw;
    height: 1.5vw;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
}