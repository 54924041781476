.manager-report-first-content {
  margin: 4vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.manager-breadcrum-div {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.manager-reports-button-section {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 47px;
}

.manager-report-box {
  border: 1px solid #d0d0ce;
  padding: 3vw;
  display: flex;
  flex-wrap: wrap;
}
