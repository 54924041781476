@mixin displaySettings2($width, $height, $margin-left, $margin-top) {
  width: $width;
  height: $height;
  margin-left: $margin-left;
  margin-top: $margin-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

#peerBackBtn {
  @include displaySettings2(auto, auto, 4.6875vw, 8.65vh);
}

#peerHead {
  @include displaySettings2(auto, 7.12vh, 1.865vw, 8.65vh);
}

#peerHeadDesc {
  @include displaySettings2(68.67vw, 5.5vh, 3.9vw, 2.5vh);
  @include fontSettings(400, 14px, 20px, #53565a);
}
