@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.commFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#commLeftBox {
  @include displaySettings2(277px, 950px, 0px, 0px);
  margin-top: 30px;
  margin-left: 49px;
  border: 1px solid #d0d0ce;
}

.commSummItems,
.commListItems {
  border-bottom: 1px solid;
}

#commSumm,
#commList {
  @include displaySettings2(215px, 16px, 13px, 22px);
  @include fontSettings(400, 12px, 16px, #000000);
  padding-bottom: 16px;
}

.headItems {
  @include displaySettings2(242px, 20px, 12px, 9px);
  @include fontSettings(600, 14px, 20px, #26890d);
  display: flex;
}

.commDescItems {
  @include displaySettings2(252px, 20px, 12px, 4px);
  @include fontSettings(400, 14px, 20px, #53565a);
  padding-bottom: 11px;
}

#commTotPosts {
  background-color: #e9f3e7;
}

.commListItemss {
  padding-bottom: 11px;
}

#commGreenArrow {
  padding-top: 15px;
}

#commGreenArrowDown {
  padding-top: 20px;
  transform: rotate(90deg);
}
