.onboard-form-label {
  color: #53565a;
  margin-top: 22px;
  margin-bottom: 5px;
}

.percent-rupees-box {
  margin-left: 51px;
  display: flex;
  cursor: pointer;
}

.percent-rupees {
  padding: 5px 8px;
  border: 1px solid #63666a;
  width: 36px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.handle-input-class {
  width: 170px;
  height: 32px;
  margin-right: 195px;
}

.box {
  margin-bottom: 40px;
  // display: flex;
  // align-items: center;
}
