@mixin displaySettings3(
  $width,
  $height,
  $display,
  $flex-direction,
  $justify-content,
  $align-items,
  $padding,
  $gap,
  $background
) {
  width: $width;
  height: $height;
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
  padding: $padding;
  gap: $gap;
  background: $background;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

@mixin flexSettingsPeer($flex, $order, $flex-grow) {
  flex: $flex;
  order: $order;
  flex-grow: $flex-grow;
}

.peerGroups {
  @include displaySettings3(
    17.8125vw,
    auto,
    flex,
    row,
    flex-start,
    flex-start,
    2vh 0.86vw,
    0.47vw,
    #ffffff
  );
  border: 1px solid #d0d0ce;
  margin-right: 3.9vw;
  margin-top: 2vh;
}

.innerPeerGroup {
  @include fontSettings(700, 1.1vw, 2.06vh, #1a202c);
  @include flexSettingsPeer(none, 0, 0);
  width: 17.2vw;
  height: auto;
  z-index: 0;
}

.peerGroupNum {
  @include fontSettings(500, 1.015vw, 2.06vh, #1a202c);
  margin-top: 1.015vw;
}
.peerGroupName {
  @include fontSettings(900, 1.015vw, 2.06vh, #1a202c);
}
.peerGroupStatus {
  @include fontSettings(500, 1.015vw, 2.06vh, #1a202c);
  margin-top: 2vh;
}
.peerGroupTime {
  @include fontSettings(400, 0.9375vw, 2.2vh, #97999b);
  @include flexSettingsPeer(none, 1, 0);
  width: 4.69vw;
  height: 2.2vh;
  z-index: 1;
}

.editBtn {
  @include fontSettings(400, 1.25vw, 2.2vh, #2c5234);
  @include flexSettingsPeer(none, 1, 0);
  width: 1.26vw;
  height: 2.2vh;
  font-family: "dds-icon";
  font-style: normal;
  margin-left: 6.5625vw;
}

.generateRep {
  @include fontSettings(400, 1.1vw, 2.75vh, #26890d);
  width: auto;
  height: 2.75vh;
  text-decoration-line: underline;
  border: none;
  background: #ffffff;
  cursor: pointer;
  margin-top: 2vh;
  margin-left: -0.5vw;
}

.peerGroupSets {
  margin-left: 3.9vw;
  margin-top: 5.9vh;
}

#peerGroupSet1 {
  display: inline-flex;
  flex-wrap: wrap;
}

#peerGroupSet2 {
  display: inline-flex;
}

#peer4CreatePeer {
  @include displaySettings3(
    19.76vw,
    5.5vh,
    inline-flex,
    row,
    center,
    center,
    1.24vh 1.25vw,
    0.625vw,
    #26890d
  );
  @include fontSettings(400, 1.1vw, 2.75vh, #ffffff);
  @include flexSettingsPeer(none, 0, 0);
  margin-top: 5.5vh;
  margin-left: 3.9vw;
  border: none;
  cursor: pointer;
}

#peer4ReturnHome {
  @include displaySettings3(
    19.765vw,
    5.5vh,
    inline-flex,
    row,
    center,
    center,
    1.24vh 1.25vw,
    0.625vw,
    #ffffff
  );
  @include fontSettings(400, 1.1vw, 2.75vh, #000000);
  @include flexSettingsPeer(none, 0, 0);
  border: 1px solid #000000;
  margin-left: 3.83vw;
  margin-top: 5.5vh;
  cursor: pointer;
}
