@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

#flexRow {
  @include displaySettings2(auto, auto, 0vw, 5vh);
  display: flex;
  flex-direction: row;
  align-items: center;
}

#GreenHeadArrow {
  @include displaySettings2(1vw, 2vh, 0px, 0px);
}

#BlackHeadArrow {
  @include displaySettings2(1vw, 2vh, 0px, 0px);
}

#firstHead {
  @include displaySettings2(auto, auto, 0px, 0px);
  @include fontSettings(700, 3.1vw, 4vw, #1a202c);
  display: inline;
}

#navGreen {
  @include displaySettings2(auto, auto, 0px, 0px);
  @include fontSettings(400, 1.09vw, 1vh, #26890d);
  margin-right: 0.5vw;
  text-decoration: none;
}

#navBlack {
  @include displaySettings2(auto, auto, 0.5vw, 0px);
  @include fontSettings(400, 1.09vw, 1vh, #000000);
  text-decoration: none;
}
.first-content {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
