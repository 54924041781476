.mas-main-nav {
  width: 100%;
  height: 56px;
  position: relative;
  background: #000000;
  margin-left: auto !important;
  margin-right: auto !important;
}

.tabs-section {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0rem 2rem 0rem 2.3rem;
  margin-left: auto !important;
  margin-right: auto !important;
}

.css-1mdftu-MuiTypography-root {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-style: "normal";
}

.MuiButtonBase-root.MuiTab-root.label-style {
  color: white;
  font-size: 16px;
  font-family: "SourceSansProRegular";
  font-weight: 600;
  font-style: normal;
  text-transform: none;
  padding: 0px 0px;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected.label-style {
  color: white;
  font-size: 16px;
  /* font-family: "SourceSansProSemiBold"; */
  font-family: "SourceSansProRegular";
  font-weight: 600;
  font-style: normal;
  text-transform: none;
  padding: 0px 0px;
}

.css-j4uvtq-MuiButtonBase-root-MuiTab-root {
  padding: 0px 0px;
}

.MuiTabs-indicatorSpan {
  max-width: 50;
}

.tab-space {
  width: 85px;
}

.MuiButtonBase-root.css-j4uvtq-MuiButtonBase-root-MuiTab-root {
  padding: 0px 0px;
}

.MuiTabs-indicator.css-1dbv7x7 {
  height: 5px !important;
  background: rgb(217, 177, 115) !important;
}

.mas-main-nav .MuiTabs-indicator.css-1yzg8hb-MuiTabs-indicator {
  height: 5px !important;
  background: rgb(217, 177, 115) !important;
}

.MuiTabs-root.css-bwa8z {
  width: 1188px;
}
