.my-subscription-container {
  padding: 0 4rem;
}
.surveyq1 {
  width: 68.67vw;
  height: 4.8vh;
  left: 3.9vw;
  top: 20.88vh;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 2.75vh;
  color: #53565a;
}

.hdn-text {
  width: 68.67vw;
  height: 4.8vh;
  left: 3.9vw;
  top: 20.88vh;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 2.75vh;
  color: #53565a;
}

.dipstickFrame {
  box-sizing: border-box;
  width: 68.6vw;
  height: 66.45vh;
  margin-top: 6.32vh;
  background: #ffffff;
  border: 1px solid #d0d0ce;
}
.q1-index {
  width: 3.5vw;
  height: 2.75vh;
  margin-left: 3.125vw;
  margin-top: 3.846vh;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  color: #26890d;
}

.q1 {
  width: 58.83vw;
  height: 4.8vh;
  margin-left: 3.125vw;
  margin-top: 1.92vh;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  color: #000000;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.24vh 1.25vh;
  gap: 0.625vw;
  width: 14.53vw;
  height: 2.75vh;
  margin-left: 2.9vw;
  margin-top: 5.1;
  background: #26890d;
}

.ans1 {
  width: 10.15625vw;
  height: 2.75vh;
  margin-left: 2.58vw;
  margin-top: 5.1vh;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  color: #000000;
}

.ans2 {
  width: 10.15vw;
  height: 2.75vh;
  margin-left: 2.58vw;
  margin-top: 5.1vh;
}

.ans3 {
  width: 10.15vw;
  height: 2.75vh;
  margin-left: 2.58vw;
  margin-top: 5.1vh;
}

.ans4 {
  width: 10.15vw;
  height: 2.75vh;
  margin-left: 2.58vw;
  margin-top: 5.1vh;
}

.dipsticks-next-button {
  margin-top: 30vh;
  margin-left: 2.9vw;
  width: 21.4vw;
  height: 5.5vh;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  border: 0px;
  background-color: #26890d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.survey-msg {
  width: 39vw;
  height: 4.94vh;
  margin-top: 10vh;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  color: #000000;
}

.survey-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 21.4vw;
  height: 5.5vh;
  margin-top: 10vh;
  background: #26890d;
  border: none;
  color: white;
  cursor: pointer;
}
