@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

#comp-head {
  @include displaySettings2(42vw, 7.12vh, 3.9vw, 4.95vh);
  @include fontSettings(600, 3.125vw, 7.14vh, #1a202c);
}

#comp-desc {
  @include displaySettings2(68.67vw, 4.8vh, 3.9vw, 2.2vh);
  @include fontSettings(400, 0.9375vw, 2.75vh, #53565a);
}

#comp-box {
  @include displaySettings2(auto, auto, 3.9vw, 6.87vh);
  padding-bottom: 6.87vh;
  padding-right: 20.6vw;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d0d0ce;
  margin-left: 3.9vw;
  margin-top: 6.32vh;
}

#compBigCirc1 {
  @include displaySettings2(1.25vw, 2.2vh, 0px, 0px);
}

#compSmallCirc {
  @include displaySettings2(0.625vw, 1.1vh, 0.3125vw, 0px);
  margin-top: -1.65vh;
}

#compBigCirc2 {
  @include displaySettings2(1.25vw, 2.2vh, 0px, 0px);
}

#compVertLine {
  @include displaySettings2(auto, 8.93vh, 0px, 0.3125vh);
}

#compEmp1 {
  @include displaySettings2(auto, auto, 0.9375vw, 0px);
}

#compEmps {
  @include displaySettings2(auto, auto, 0.9375vw, 6vh);
}

.time {
  display: inline;
  word-wrap: break-word;
  color: #bbbcbc;
}

#comp-pdfDownload {
  @include displaySettings2(25vw, 3.71vh, 7.03vw, 0.4vh);
}

.comp-para {
  @include displaySettings2(19.84vw, 4.95vh, 7.03vw, 7.07vh);
  @include fontSettings(400, 12px, 150%, #75787b);
}

#comp-pdfIcon {
  @include displaySettings2(auto, auto, 0px, 0px);
}

#comp-pdfName {
  @include fontSettings(400, 1.1vw, 2.75vh, #53565a);
  @include displaySettings2(auto, auto, 0.625vw, 0.275vh);
}

#comp-dw1 {
  @include displaySettings2(0.74vw, 1.7vh, 2.7vw, 0.63vh);
  background: #ffffff;
}

#comp-dw2 {
  @include displaySettings2(1.25vw, 0.55vh, 2.4vw, 0px);
  background: #ffffff;
}

#comp-review {
  @include displaySettings2(8.5vw, 3.3vh, 0px, 0px);
  margin-left: 7.03vw;
  margin-top: 5.5vh;
  background: rgba(0, 0, 0, 0.08);
}

#comp-reviewIcon {
  @include displaySettings2(1.1vw, 1.92vh, 0.39vw, 0.82vh);
}

#comp-reviewText {
  @include displaySettings2(6.1vw, 2.2vh, 0.47vw, 0.55vh);
  @include fontSettings(400, 0.9375vw, 2.2vh, #005478);
}

#comp1-inp {
  @include displaySettings2(35vw, 4.4vh, 0px, 0px);
  @include fontSettings(400, 1.1vw, 2.75vh, #63666a);
  border: 1px solid #d0d0ce;
  margin-top: 5.1vh;
}

#comp1-sub {
  @include displaySettings2(10.3vw, 4.95vh, 0px, 0px);
  @include fontSettings(400, 1.1vw, 2.75vh, #ffffff);
  background: #26890d;
  border: none;
}

#comp-u {
  @include displaySettings2(9.52vw, 2.2vh, 0px, 7.83vh);
  @include fontSettings(700, 0.9375vw, 2.2vh, #53565a);
}

#comp-u2 {
  @include displaySettings2(15.23vw, 2.2vh, 0px, 0.41vh);
  @include fontSettings(400, 0.9375vw, 2.2vh, #53565a);
}

#comp-chooseFile {
  @include displaySettings2(7.6vw, 4.4vh, 0px, 0px);
  margin-left: 4.3vw;
  margin-top: 7.3vh;
  color: #ffffff;
  background: #26890d;
  opacity: 0.4;
  border: none;
}

#comp-submit {
  @include displaySettings2(26vw, 5.5vh, 0px, 0px);
  margin-left: 0px;
  margin-top: 1.8vh;
  color: #ffffff;
  background: #26890d;
  border: none;
}

#comp-help {
  @include displaySettings2(26vw, 5.5vh, 0.78vw, 0.55vh);
  @include fontSettings(400, 1.1vw, 2.75vh, #53565a);
  background: #ffffff;
  margin-left: 3.44vw;
  margin-top: 3.16vh;
}

#compGreyLine {
  @include displaySettings2(auto, auto, 0px, 0px);
  margin-left: 2.65vw;
  margin-top: 6vh;
}

#compRCone {
  @include displaySettings2(1.5vw, 3.3vh, 0.625vw, 6.32vh);
  @include fontSettings(600, 1.25vw, 3.3vh, #53565a);
}

#compRCupdate {
  @include displaySettings2(15.1vw, 2.75vh, 0.625vw, 6.32vh);
  @include fontSettings(400, 1.1vw, 2.75vh, #000000);
}

.compRCpara {
  @include displaySettings2(20vw, 6.04vh, 0.625vw, 1.4vh);
  @include fontSettings(400, 1.1vw, 2.75vh, #53565a);
}

#compGreenLine {
  @include displaySettings2(auto, auto, 0px, 0px);
  margin-left: 2.65vw;
  margin-top: 2.2vh;
}

#compRCtwo {
  @include displaySettings2(1.5vw, 3.3vh, 0.625vw, 2.2vh);
  @include fontSettings(600, 1.25vw, 3.3vh, #26890d);
}

#compRCupload {
  @include displaySettings2(15.1vw, 2.75vh, 0.625vw, 2.2vh);
  @include fontSettings(400, 1.1vw, 2.75vh, #000000);
}

#comp2-update {
  @include displaySettings2(41.9vw, 7.14vh, 0px, 0px);
  margin-top: -2.75vh;
  @include fontSettings(600, 1.875vw, 7.14vh, #1a202c);
}

#comp-approval {
  @include displaySettings2(6.95vw, 3.3vh, 7vw, 6.32vh);
}

.comp-emp {
  @include displaySettings2(10.3125vw, 4.53vh, 0, 0);
  @include fontSettings(400, 0.9375vw, 1.65vh, #53565a);
}
