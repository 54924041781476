@mixin centerAlignment {
  display: flex;
  align-items: center;
}

.header-main-div {
  background: #000000;
  @include centerAlignment();
  height: 3.8vw;
}

.header-logo-image {
  margin-left: 1.2vw;
}

.header-nav-list {
  list-style: none;
  display: flex;
  gap: 1vw;
  color: #ffffff;
  padding-left: 2vw;
}

.header-nav-list-item {
  cursor: pointer;
  text-decoration: none;
  height: 3vw;
  padding: 0.2vw;
  justify-content: space-around;
  @include centerAlignment();
  &:hover {
    background: #26890d;
  }
  color: white;
}

header-name {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: black;
  }
}

.header-user-avatar {
  margin: 1%;
  margin-left: auto;
}

.header-logout-button {
  position: absolute;
  margin-left: -1%;
}
.activeLink {
  background: #26890d;
  text-decoration: none;
}

.tab {
  text-decoration: none;
}
.avatar {
  width: 4vw;
  height: auto;
}
