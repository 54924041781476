.main_form {
    width: 87vw;
    flex-direction: column;
    // overflow-x: scroll;
    // align-items: center;
  }
.form-style{
    display: flex;
    flex-direction: column;
    gap: 1vw;

}
.sub_form {
    display: flex;
    width: fit-content;
    padding: 1.25rem 1.5rem;
    border: 0.11rem solid #d0d0ce;
}
.sub_form > div label {
    font-size: 1vw;
  }
  
  .sub_form > div input:not(:last-child),
  .sub_form > div select {
    margin-top: 0.4rem;
    padding: 0.4rem 0.9rem;
    height: auto;
    background: #ffffff;
    border: 0.1rem solid #d0d0ce;
    margin-right: 1.5rem;
    width: 10vw;
    font-size: 1vw;
    color: #090909;
  }
  
  .sub_form > div input:not(:last-child)::placeholder,
  .sub_form > div select:invalid {
    color: #63666a;
  }
  
  input[type="date"] {
    font-family: "Open Sans";
    color: #000000;
    padding: 0.3rem 0.9rem !important;
  }
  
  input[type="date"]::placeholder {
    color: #63666a;
  }

  input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    -webkit-text-fill-color: rgb(0, 0, 0) !important;
    }
.control_btn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    }
    
    .control_btn button {
    background: transparent;
    border: none;
    }
.uploadFile {
    display: flex;
  }
  
  .upload_btn {
    background-color: #26890d;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: white;
    margin-right: 1.2rem;
    width: fit-content;
  }

  .file_name {
    align-items: center;
    width: 5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 0.6rem;
    margin-right: 1rem;
    font-size: 1vw;
  }

  .add_remove_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .submit_btn {
    display: block;
    background-color: #26890d;
    cursor: pointer;
    padding: 0.7rem 1.6rem;
    color: white;
    font-size: 1vw;
    border: none;
    width: fit-content;
  }
  
  .submit_btn:hover {
    background-color: #26890dd6;
  }