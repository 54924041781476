@mixin displaySetting($direction, $alignment, $gap) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignment;
  gap: $gap;
}

@mixin boxSetting($width, $height, $padding, $border, $background) {
  width: $width;
  height: $height;
  padding: $padding;
  box-sizing: border-box;
  border: $border;
  background: $background;
}

.reports {
  @include displaySetting(column, flex-start, 0.78vw);
  @include boxSetting(
    38vw,
    auto,
    2.2vh 0px 2.5vh 1vw,
    1px solid #d0d0ce,
    #ffffff
  );
}

.reports-header {
  @include displaySetting(row, flex-start, 1.37vw);
  width: 100%;
}

.reports-header-text {
  padding-top: 0.68vh;
}

.reports-number {
  color: #ffffff;
  justify-content: center;
  @include displaySetting(row, center, 0px);
  @include boxSetting(1.95vw, 4vh, 0.55vh 0.4vw, 0, #26890d);
  margin-top: 0.8vh;
}

.reports-image {
  margin-left: auto;
  padding: 1.1vh 1.72vh 1.785vh 0px;
}

.reports-box {
  height: 14.7vh;
  @include displaySetting(row, flex-start, 1.875vw);
}

.reports-box-data {
  isolation: isolate;
  @include displaySetting(column, flex-start, 0px);
  @include boxSetting(
    20.3125vw,
    100%,
    1.236vh 1.48vw 2.2vh 1.17vw,
    1px solid #bbbcbc,
    #ffffff
  );
}

.reports-box-description {
  padding-top: 0.824vh;
}

.reports-box-timeStamp {
  margin-top: auto;
}

.reports-button {
  margin-top: auto;
  text-decoration-line: underline;
  color: #26890d;
  cursor: pointer;
}
