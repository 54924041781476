@mixin displaySettings2($width, $height, $margin-left, $margin-top) {
  width: $width;
  height: $height;
  margin-left: $margin-left;
  margin-top: $margin-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

#ddsicon1 {
  @include displaySettings2(6.12px, 9.41px, 60px, 84px);
  flex: none;
  order: 0;
  flex-grow: 0;
}

#peer1img1 {
  @include displaySettings2(23.4375vw, 41.21vh, 3.36vw, 0.82vh);
}

#noPeer {
  @include displaySettings2(20.9375vw, 4.945vh, 3.9vw, -9.34vh);
  @include fontSettings(700, 24px, 36px, #000000);
}

#noPeerDesc {
  @include displaySettings2(68.67vw, 2.75vh, 3.9vw, 0.823vh);
  @include fontSettings(400, 14px, 20px, #000000);
}

#createPeer {
  @include displaySettings2(21.4vw, 5.5vh, 3.9vw, 3.3vh);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.24vh 1.25vw;
  gap: 0.625;
  background: #26890d;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
