.contained-button {
  background: #26890d;
  gap: 0.625vw;
  border: #26890d;
  cursor: pointer;
  color: #ffffff;
}

.modal-contained-button-normal {
  background: #26890d;
  border: #26890d;
  color: #ffffff;
  width: 15.86vw;
  height: 4.4vh;
  padding: 1.24vh 1.25vw;
  cursor: pointer;
}

.modal-outlined-button-small {
  background: #53565a;
  border: #53565a;
  color: #ffffff;
  width: 5.78;
  height: 4.4vh;
  padding: 1.24vw 1.25vw;
  cursor: pointer;
}

.outlined-button {
  background: #ffffff;
  border: 1px solid #53565a;
  color: #53565a;
  cursor: pointer;
}

.small {
  width: 7.58vw;
  height: 4.4vh;
  padding: 0.69vh 0.9375vw;
}

.normal {
  width: 26vw;
  height: 5.5vh;
  padding: 1.24vh 1.25vw;
  margin-top: 6.32vh;
}

.greenColor {
  color: #26890d;
  border: 1px solid #26890d;
}

.size {
  // height: 32px;
  padding: 0.69vh 0.9375vw;
}
.download {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.78125vw;
}

.image {
  width: 2.34375vw;
}

.contained-button-green-normal {
  background-color: #26890d;
  color: #ffffff;
  border: none;
  width: 7.58vw;
  height: 4.4vh;
  margin-top: 3vh;
  cursor: pointer;
}
