@mixin boxSettings($padding, $background, $width, $height) {
  padding: $padding;
  background: $background;
  width: $width;
  height: $height;
}

@mixin displaySettings {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.peer-set-box {
  border: 1px solid #bbbcbc;
  @include boxSettings(2.2vh 0px 2.5vh 1vw, #ffffff, 20vw, auto);
}

.peer-set-box-header {
  @include displaySettings;
}

.peer-set-box-header-text {
  @include boxSettings(1vh 0.3125vw, #fff, auto, 2.75vh);
  @include displaySettings();
}

.peer-set-box-image {
  margin-left: auto;
  padding-right: 1.72vw;
}

.peer-set-box-data {
  margin: 2vh 1vw 1vw 0;
}

.peer-set-box-button {
  text-decoration-line: underline;
  color: #26890d;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 2.5vh;
}

.peer-prev-act {
  padding-right: 1vw;
}
