@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.commFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#commDesc {
  @include displaySettings2(879px, 35px, 50px, 30px);
  @include fontSettings(400, 14px, 20px, #53565a);
}

#commBtnWhite,
#commBtnBlack,
#commMyActBtnWhite,
#commMyActBtnBlack {
  @include displaySettings2(105px, 32px, 0px, 0px);
  @include fontSettings(400, 14px, 20px, #53565a);
}

#commBtnWhite {
  margin-left: 50px;
  margin-top: 30px;
  background-color: #ffffff;
  border: none;
}

#commBtnBlack {
  margin-left: 50px;
  margin-top: 30px;
  background-color: #000000;
  color: #ffffff;
  border: none;
}

#commMyActBtnWhite {
  background-color: #ffffff;
  border: none;
}

#commMyActBtnBlack {
  background-color: #000000;
  color: #ffffff;
  border: none;
}
