.my-subscription-container {
  display: flex;
  flex-direction: column;
}

.frame {
  box-sizing: border-box;
  width: 90vw;
  height: 35vw;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d0d0ce;
}
.logo{
  width: 5vw;
}
.ln {
  margin: 2vw;
  background: rgba(255, 255, 255, 0.002);
  box-shadow: inset 0px -1px 0px #d0d0ce;
}
.hd {
  font-weight: 600;
}

.sum-head {
  display: flex;
  margin-left: 3vw;
  flex-flow: column;
}
.summary-details {
  display: flex;
  flex-direction: column;
  margin: 2vw;
}
.summary-details-row {
  display: flex;
  flex-direction: row;
}
.summary-details-item {
  width: 25vw;
  color: #53565a;
  font-size: 1vw;
}
.summary-btn {
  display: flex;
  flex-direction: row;
  column-gap: 2vw;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;

  position: absolute;
  width: 186px;
  height: 20px;
  left: 87px;
  top: 522px;
  bottom: 23.35%;
  background: #26890d;
}

.next-button {
  width: 194px;
  height: 50px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  border: 0px;
  align-items: center;
}

.approve-btn {
  box-sizing: border-box;
  display: flex;
  width: 20vw;
  background: #26890d;
  border: 1px solid #26890d;
  justify-content: space-around;
}

.cancel-button {
  width: 90px;
  height: 22px;
  border: 0px;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.approve-button1 {
  border: 0px;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.approve-button {
  width: 251px;
  height: 22px;
  display: flex;
  border: 0px;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.name-frame {
  position: absolute;
  width: 277px;
  height: 37px;
  left: 87px;
  top: 340px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.name {
  left: 87px;
  right: 916px;
  top: 400px;
  bottom: 299px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.company-frame {
  position: absolute;
  width: 237px;
  height: 37px;
  left: 347px;
  top: 340px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #53565a;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.company {
  left: 347px;
  right: 696px;
  top: 400px;
  bottom: 299px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.contact-frame {
  position: absolute;
  width: 277px;
  height: 37px;
  left: 87px;
  top: 435px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #53565a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.contact {
  left: 87px;
  right: 916px;
  top: 463px;
  bottom: 236px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}

.email-frame {
  position: absolute;
  width: 237px;
  height: 37px;
  left: 347px;
  top: 435px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.email {
  left: 347px;
  right: 696px;
  top: 463px;
  bottom: 236px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.onboard-label {
  color: #53565a;
}
.decline-btn {
  box-sizing: border-box;
  display: flex;
  width: 15vw;
  border: 1px solid #da291c;
  justify-content: space-around;
}
.approve-btn1 {
  box-sizing: border-box;
  display: flex;
  width: 15.8vw;
  background: #26890d;
  justify-content: space-around;
  border: 1px solid #26890d;
}
.cancel-btn1 {
  box-sizing: border-box;
  display: flex;
  width: 5.7vw;
  justify-content: space-around;
  background-color: #53565a;
}

.onboard-form-select {
  margin-bottom: 5px;
}
.proceed-modal-button {
  margin-top: 30px;
}
