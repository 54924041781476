.onereport-first-content {
  padding: 20px 42px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.oneReport-header-section {
  display: flex;
  flex-direction: row;
  gap: 17px;
  width: 95%;
  align-items: center;
}

.onereport-breadcrum-div {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.icon-section {
  // height: min-content;
  // padding: 0px 5px;
  border: 1px solid #53565a;
  display: flex;
  // color:#53565A;
  cursor: pointer;
}

.green-icon-section {
  // height: min-content;
  // padding: 0px 5px;
  border: 1px solid #26890d;
  display: flex;
  // color:#26890D;
  cursor: pointer;
}

.oneReport-data-section {
  height: auto;
  border: 1px solid #d0d0ce;
  padding: 40px;
}

.onereport-subheader {
  display: flex;
  gap: 10px;
  width: 90%;
  align-items: center;
}
