@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.commFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#commRightBox {
  @include displaySettings2(879px, 950px, 0px, 0px);
  margin-left: 25px;
  margin-top: 30px;
  border: 1px solid #d0d0ce;
}

#commSearchBar {
  @include displaySettings2(658px, 32px, 0px, 0px);
  margin-left: 20px;
  margin-top: 32px;
}

#commMyActSearchBar {
  @include displaySettings2(518px, 32px, 0px, 0px);
  margin-left: 20px;
  margin-top: 32px;
}

#commFilter {
  @include displaySettings2(24px, 24px, 14px, 36px);
}

#commPostQuery {
  @include displaySettings2(123px, 40px, 0px, 0px);
  margin-left: 28px;
  margin-top: 28px;
  background-color: #ffffff;
  border: none;
  color: #26890d;
  border: 1px solid #26890d;
}

#commMyActPostQuery {
  @include displaySettings2(223px, 40px, 0px, 0px);
  margin-left: 28px;
  margin-top: 28px;
  background-color: #26890d;
  border: none;
  color: #ffffff;
}

#commStar {
  @include displaySettings2(auto, auto, 16px, 43px);
}

#commPostType {
  @include displaySettings2(260px, 16px, 3px, 47px);
  @include fontSettings(400, 12px, 16px, #53565a);
}

#commPin,
#commCopy,
#commEdit,
#commDel {
  @include displaySettings2(auto, auto, 9px, 36px);
}

#commPin {
  margin-left: 430px;
}

#commPostHead {
  @include displaySettings2(810px, 40px, 16px, 9px);
  @include fontSettings(600, 16px, 20px, #000000);
}

#commAns,
#commReply,
#commView {
  @include displaySettings2(auto, auto, 36px, 22px);
}

#commAns {
  padding-left: 16px;
}

#commAnsTxt,
#commReplyTxt,
#commViewTxt {
  @include displaySettings2(auto, auto, 8px, 22px);
  @include fontSettings(400, 12px, 16px, #53565a);
}

#commmHr {
  @include displaySettings2(810px, 0px, 16px, 0px);
  margin-top: 22px;
}

#commPP {
  margin-left: 64px;
  margin-top: 20px;
}

#commPPName {
  @include displaySettings2(262px, 15px, 16px, 41px);
  @include fontSettings(400, 12px, 16px, #53565a);
}

#commJustNow {
  @include displaySettings2(123px, 16px, 357px, 41px);
  @include fontSettings(400, 12px, 16px, #53565a);
}

#commPPLink {
  @include displaySettings2(695px, 20px, 16px, 6px);
  @include fontSettings(400, 14px, 20px, #53565a);
}

#commLink {
  @include fontSettings(700, 14px, 20px, #007cb0);
}

#commPrev,
#commNxt {
  @include displaySettings2(auto, auto, 0px, 0px);
  @include fontSettings(400, 14px, 20px, #000000);
  border: none;
  background-color: #ffffff;
  margin-left: 40px;
  margin-top: 41px;
}

#commPrev {
  margin-left: 350px;
}

#commPg1 {
  @include displaySettings2(32px, 32px, 16px, 8px);
  margin-left: 28px;
  margin-top: 36px;
  background-color: #000000;
  color: #ffffff;
}

.commPgs {
  @include displaySettings2(32px, 32px, 16px, 45px);
}
