.onboard-Title {
  padding: 36px 50px;
  display: flex;
  flex-direction: column;
  gap: 38px;
}

.onboard-form-area {
  display: flex;
  flex-direction: row;
  gap: 34px;
  margin: 9px 0 0 50px;
}

.onboard-modal-header {
  color: #000000;
}

.onboard-modal-description-boxes {
  color: #53565a;
}

.onboard-modal-button-group {
  margin-top: 31px;
}

.onboard-modal-same-prop {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
}
