.my-subscription-container {
  padding: 0 4rem;

  .mas-subscription-apiproduct {
    width: 1192px;
    height: 648px;
    top: 385px;
    left: 125px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    box-shadow: inset 0px -1px 0px #d3d3d3;
  }

  .mas-style-select {
    position: static;
    width: 265px;
    height: 40px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .MuiFormControl-root.css-b16mh4-MuiFormControl-root {
    margin: 8px 0px 0px 0px;
  }

  .mas-subs-textfield {
    width: 812px !important;
  }

  .clear-filter {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
  }

  .no-records {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
  }

  .loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .filter-section {
    // display: flex;
    // flex-direction: row;
    // justify-content: ;

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0;
  }
}

.mas-subdetails-unsub-paragraph {
  line-height: 150% !important;
}

.request-text {
  // position: absolute;
  width: 879px;
  height: 35px;
  left: 50px;
  top: 176px;
  margin-top: 24px;

  /* body.text.m.l [14px Regular]

/font: Open Sans
/size: 14px
/weight: regular
/line.height: 20px
*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  color: #53565a;
}

.act-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  margin-right: 617px;
  // position: absolute;
  width: 513px;
  height: 40px;
  left: 50px;
  top: 251px;

  background: rgba(255, 255, 255, 0.002);
  /* Dividers / Bottom: 1px - Cool Grey #D0D0CE */

  box-shadow: inset 0px -1px 0px #d0d0ce;
}

.all {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 86px;
  height: 40px;

  background: #000000;

  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.all-num {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 4px;

  width: 22px;
  height: 24px;

  background: #d0d0ce;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 30px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Black #000000 */

  color: #000000;
}

.pending {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  width: 124px;
  height: 40px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.pending-num {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 4px;

  width: 22px;
  height: 24px;

  background: #d0d0ce;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 20px;
}

.approved {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 134px;
  height: 40px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.approved-num {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 4px;

  width: 22px;
  height: 24px;

  background: #d0d0ce;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 13px;
}

.rejected {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 116px;
  height: 40px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}

.rejected-num {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 4px;
  width: 22px;
  height: 24px;
  background: #d0d0ce;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 13px;
}
