.dialog-title {
  margin: 0rem;
  font-family: "SourceSansProSemiBold";
  font-weight: 600;
}
.custom-css {
  &.min-height {
    min-height: 160px;
  }
  .product-certificate-details {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #4a4a4a;
    margin-bottom: 24px;
  }
  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    .certificate-alias-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      color: #4a4a4a;
    }
    .certificate-alias-value {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #717171;
    }
    .red {
      color: #c9252d;
      &.error {
        margin: 5px 0 0;
      }
    }
    .mandatory-field {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .info {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #717171;
    }
    &.datepicker-row {
      position: relative;
    }
    .datepicker-icon {
      position: absolute;
      width: 25px;
      right: 35%;
      top: 8px;
    }
  }
  .product-title {
    font-weight: bold;
  }
  .count-display {
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
  }
  .error {
    display: flex;
    color: #c9252d;
    font-size: 16px;
    line-height: 24px;
    img {
      margin-right: 5px;
    }
  }
  .add-certificate-info {
    margin-top: 24px;
    div {
      display: flex;
    }
    .certificate-info-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #4a4a4a;
    }
    ul {
      padding-left: 24px;
      margin-top: 12px;
      li {
        margin-bottom: 4px;
        margin-bottom: 4px;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .key,
    .value {
      font-size: 16px;
      line-height: 24px;
      color: #717171;
    }
    .key {
      font-weight: 600;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
  .spec-data {
    margin-bottom: 25px;
  }
  .specification-details {
    display: flex;
    align-items: center;
    border-top: 1px solid #d3d3d3;
    .specificationsheader {
      font-weight: 700;
      color: #4a4a4a;
    }
    .row-header {
      font-weight: 600;
    }
    .specificationswidth {
      margin: 0;
      padding: 16px;
      &:first-child {
        width: 25%;
      }
      &:nth-child(2) {
        width: 50%;
        word-break: break-all;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &.test-connectivity {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .error-msg,
        .success-msg {
          width: 100%;
          word-break: break-word;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          img {
            margin-top: 13px;
          }
        }
        .msg {
          margin-left: 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #717171;
          margin-top: 10px;
        }
        .cta {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          cursor: pointer;
          color: #8d7249;
          text-decoration: underline;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid #d3d3d3;
    }
  }
  .publish-error {
    padding: 16px 24px;
    background: #f9dede;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4a4a4a;
    margin-top: 24px;
    img {
      margin-right: 7px;
    }
  }
  .react-datepicker {
    .react-datepicker__triangle {
      display: none;
    }
  }
  .react-datepicker__input-container {
    .datepicker {
      height: 40px;
      border: 1px solid;
      border-radius: 2px;
      width: 64%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-left: 13px;
      &::after {
        content: "reme";
      }
    }
  }
  .react-datepicker-popper {
    position: relative !important;
    inset: 0 !important;
    transform: none !important;
    padding-top: 0 !important;
    .react-datepicker {
      width: 67%;
    }
  }
  .react-datepicker__day {
    color: #8d7249;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 10px 3.5%;
  }
  .react-datepicker__day--disabled {
    color: #ccc;
  }
  .react-datepicker__day--selected {
    font-weight: 700;
    background-color: transparent;
    &:hover {
      background-color: #f2f2f2;
    }
  }
  .react-datepicker__header {
    background-color: transparent;
    border-bottom: 0;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 10px;
  }
  .react-datepicker__current-month {
    padding: 10px;
    color: #8d7249;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #d3d3d3;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #8d7249;
  }
  .react-datepicker__day-name {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #4a4a4a;
    padding: 8px 3.5%;
    margin: 0;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .error-screen {
    .row {
      margin-bottom: 24px;
      img {
        width: 24px;
        margin-right: 10px;
      }
    }
    .dark-text {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      flex-direction: row;
    }
    .light-text {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
