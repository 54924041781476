
#drop_zone {
    display: grid;
    width:  23vw;
    height: 10vw;
    place-content: center;
    border: 2px dashed lightgray;
    padding: 16px;
    text-align: center;
}

.fp-label {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 8px;
  background-color:  rgb(38, 137, 13);
  color: white;
}

.incumbent-onboard-Title {
  padding: 4.95vh 3.91vw;
  display: flex;
  flex-direction: column;
  gap: 2.97vw;
}

.incumbent-onboard-form-area {
  display: flex;
  flex-direction: row;
  gap: 2.9vw;
  margin: 1.24vh 0 0 3.91vw;
}
.download-sample-file {
  text-decoration: none;
  width: fit-content;
}
.Incumbent-onboard-form {
  box-sizing: border-box;
  margin-bottom: 1.376vh;
  background: #ffffff;
  border: 1px solid #d0d0ce;
  padding: 1.1vh 3.05vw;
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: none;
  width: 68.6vw;
  height: fit-content;
}

.upload-salary-form {
  margin: 1.5vh 0 2.03vw 1.5vh;
  display: flex;
  flex-direction: column;
}

.upload-salary-form-header {
  width: 39.84vw;
}

.dropBox {
  margin: 3.3vh 0;
  width: 37.19vw;
  height: 24.73vh;
  border: 1px dashed #d0d0ce;
  color: #53565a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.7vw;
}

// .fileupload-section{
//     margin-right: 28px;
// }

.incumbent-upload-file {
  display: none;
}

.incumbent-uploaded-file-name {
  display: flex;
  flex-direction: row;
  margin-top: 2.2vh;
  border-bottom: 4px solid #26890d;
  width: 25vw;
  height: 4.4vh;
  align-items: flex-end;
}

.incumbent-pdf-image-upload {
  width: 1.5625vw;
  height: 2.75vh;
  margin-bottom: 0.14vh;
  margin-right: 0.78vw;
}

.onboarding-submit-help-btns {
  padding: 0 !important;
  width: 26vw;
  height: 5.5vh;
  margin-top: 3vh;
}

.onb-choose-file {
  background-color: #26890d;
  color: #ffffff;
  border: none;
  width: 7.58vw;
  height: 4.4vh;
  margin-top: 3vh;
  cursor: pointer;
}
