@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

#PartnerCompLeftArrowBox {
  @include displaySettings2(1.2vw, 2.5vh, 0px, 0px);
  background: #ffffff;
  border: 1px solid #26890d;
}

#PartnerCompLeftArrow {
  @include fontSettings(400, 1.2vw, 1vh, #26890d);
}

#partnerCompBoxHead {
  @include fontSettings(600, 1.8vw, 3.125vw, #000000);
}

#partnerCompBoxSub {
  @include fontSettings(700, 0.93vw, 1.25vw, #000000);
}

#partnerCompBoxSub2 {
  @include fontSettings(400, 0.93vw, 1.25vw, #97999b);
  display: flex;
  flex-direction: row;
}

#PartnerCompRightArrowBox {
  @include displaySettings2(1.2vw, 2.5vh, 0px, 0px);
  background: #ffffff;
  border: 1px solid #26890d;
}

#PartnerCompRightArrow {
  @include fontSettings(400, 1.2vw, 1vh, #26890d);
  display: flex;
  flex-direction: row;
}
