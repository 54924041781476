.mas-select {
  font-family: "SourceSansProRegular";
  display: flex;
  flex-direction: column;
  p {
    padding: 0 1rem;
  }
}
.mas-select-label {
  height: 24px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
}

.mas-select-sub-label {
  position: static;
  font-family: "SourceSansProRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #717171;
  flex: none;
  flex-grow: 0;
  margin: 4px 0 8px;
  width: max-content;
}

.mas-select-discription-content-break {
  display: block;
  color: #717171;
  white-space: normal;
}
