@mixin displaySettings2($width, $height, $padding-left, $padding-top) {
  width: $width;
  height: $height;
  padding-left: $padding-left;
  padding-top: $padding-top;
}

@mixin fontSettings($font-weight, $font-size, $line-height, $color) {
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

#repHead {
  @include displaySettings2(11.8vw, 7.14vh, 3.9vw, 11.54vh);
  @include fontSettings(600, 40px, 52px, #1a202c);
}

.repNavs {
  margin-left: 3.125vw;
}

.repNavGreen {
  @include displaySettings2(5.7vw, 2.75vh, 0.78vw, 19.37vh);
  @include fontSettings(400, 14px, 20px, #26890d);
  margin-right: 0.625vw;
}

.repNavBlack {
  @include displaySettings2(5.7vw, 2.75vh, 0.78vw, 19.37vh);
  @include fontSettings(400, 14px, 20px, #000000);
  margin-right: 0.625vw;
}

#repDesc {
  @include displaySettings2(78vw, auto, 0vw, 4.1vh);
  @include fontSettings(400, 14px, 20px, #53565a);
}

#repCurrYr {
  @include displaySettings2(8.4vw, 4.4vh, 0px, 0.4vh);
  margin-left: 3.9vw;
  margin-top: 4.1vh;
  @include fontSettings(400, 14px, 20px, #ffffff);
  background-color: #000000;
}

#repHist {
  @include displaySettings2(7.65vw, 4.4vh, 0px, 0.4vh);
  margin-top: 4.12vh;
  @include fontSettings(400, 14px, 20px, #000000);
  background-color: #ffffff;
  border: none;
}
#repAssistFlex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#repInfo {
  margin-left: 52.9vw;
}

#repAssist {
  @include displaySettings2(auto, 2.75vh, 0.4vw, 0px);
  @include fontSettings(400, 0.9375vw, 2.75vh, #000000);
}

#repVertLine {
  @include displaySettings2(0.234vw, 12.2vh, 0vw, 5.77vh);
}

.repFlexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

#repAncInner,
#rep6more,
#repPeerInner,
#repMsg,
#repExtLink,
#repRightArrow,
#repNameYr {
  display: inline;
}

#repName,
#repAnc,
#repPeer {
  @include fontSettings(400, 14px, 20px, #1a202c);
}

#repName {
  @include displaySettings2(24.84vw, auto, 0.7vw, 3.84vh);
}

#repBoxHead {
  @include displaySettings2(auto, auto, 2.9vw, 3vh);
}

#repName2 {
  @include fontSettings(600, 1.875vw, 5.5vh, #000000);
}

#repNameYr {
  @include fontSettings(600, 1.25vw, 5.5vh, #000000);
}

#repAnc {
  @include displaySettings2(24.84vw, auto, 0.7vw, 2vh);
}

#repRec,
#repAncInner,
#repPeerInner,
#repRec2 {
  @include fontSettings(400, 12px, 16px, #97999b);
}

#repRec {
  @include displaySettings2(24.5vw, 2.2vh, 0.7vw, 0.275vh);
}

#repPeer {
  @include displaySettings2(84vw, auto, 0.7vw, 1.5vh);
}

#rep6more {
  @include fontSettings(600, 12px, 20px, #26890d);
  cursor: pointer;
}

#repCont {
  width: 93.75vw;
}

#repCont1 {
  margin-right: 11.72vw;
}

#dw1 {
  margin-left: 0.68vw;
  margin-top: 1.37vh;
  width: 0.736vw;
  height: 1.43vh;
}

#dw2 {
  margin-left: 0.47vw;
  width: 1.25vw;
  height: 0.55vh;
}

#dwBtn {
  background-color: #26890d;
  width: 3.125vw;
  height: 5.5vh;
  border: none;
  display: flex;
  flex-direction: column;
  margin-left: 60.625vw;
  margin-top: 3vh;
}

#repSumm {
  @include displaySettings2(18.125vw, 3.3vh, 3.3vw, 2.88vh);
  @include fontSettings(600, 16px, 24px, #75787b);
}

#repLeft {
  width: 39vw;
}

#repSpon,
#repSamp,
#repFiles {
  margin-left: 3.2vw;
  margin-top: 6.2vh;
  width: 10vw;
}

#repSect,
#repMainAnc {
  margin-left: 10vw;
  margin-top: 6.2vh;
}

.repSponName,
.repSect,
.repSamp,
.repMainAnc,
.repPeer,
.repFiles {
  @include fontSettings(400, 12px, 16px, #53565a);
}

.repSponNameText,
.repSectText,
.repSampText,
.repMainAncText,
.repPeerText {
  @include fontSettings(400, 14px, 20px, #000000);
}

#repFiles {
  margin-left: 3.2vw;
}

#repPeer2 {
  margin-left: 53.8vw;
  margin-top: -35.88vh;
}

.repPeer2Text {
  @include displaySettings2(30vw, 16.2vh, 0px, 0px);
}

#repBoxStuff {
  align-items: flex-start;
}

#repViewAll {
  @include displaySettings2(6.25vw, 4.12vh, 0px, 0.69vh);
  margin-top: 1.1vh;
  color: #26890d;
  border: 1px solid #26890d;
  background-color: #ffffff;
  margin-bottom: 6.7vh;
}