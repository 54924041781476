@mixin displaySettings($direction, $gap, $alignment) {
  display: flex;
  flex-direction: $direction;
  gap: $gap;
  align-items: $alignment;
}

@mixin boxSettings($width, $height) {
  width: $width;
  height: $height;
}

.onboard-Title {
  padding: 36px 50px;
  display: flex;
  flex-direction: column;
  gap: 38px;
}

.upload-onboard-form-area {
  display: flex;
  flex-direction: row;
  gap: 34px;
  margin: 9px 0 0 50px;
}

.upload-Onboard-form {
  box-sizing: border-box;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #d0d0ce;
  padding: 8px 39px;
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: none;
  width: 878px;
  height: max-content;
}

.upload-salary-form {
  margin: 11px 0 26px 11px;
}

.upload-salary-form-header {
  width: 510px;
}

.Onboard-form-setup {
  @include displaySettings(row, 50px, none);
}

.onboard-form-label {
  color: #53565a;
  margin-top: 22px;
  margin-bottom: 5px;
}

.onboard-form-select {
  border: 1px solid #d0d0ce;
  background: #ffffff;
  box-sizing: border-box;
  @include boxSettings(358px, 32px);
}

.slider-box {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
}

.slider-box-tags {
  margin-top: 12px;
}

.upload-file {
  display: none;
}

.uploaded-file-name {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border-bottom: 4px solid #26890d;
  width: 320px;
  height: 32px;
  align-items: flex-end;
}

.pdf-image-upload {
  width: 20px;
  height: 20px;
  margin-bottom: 1px;
  margin-right: 10px;
}
