.dashboard-content {
  padding: 2.75vh 3.28vw;
  display: flex;
  flex-direction: column;
  gap: 2.97vw;
}

.dashboard-content-box {
  display: flex;
  flex-direction: row;
  column-gap: 1.5vw;
}

.modal-header {
  display: flex;
  flex-direction: row;
  color: #000000;
  border-bottom: 1px solid #d0d0ce;
  padding: 1.92vh 1.875vw;
  width: 92.5%;
}

.cross-icon-image {
  margin-left: auto;
  cursor: pointer;
}

.modal-description-boxes {
  padding: 2vh 0.86vw;
  border: 1px solid #d0d0ce;
  display: flex;
  flex-direction: column;
  gap: 0.55vw;
  width: 21.5625vw;
  height: 100%;
  min-height: 15vh;
  cursor: pointer;
}

.modal-description-area {
  margin: 5.9vh 2.73vw;
  display: flex;
  flex-direction: row;
  gap: 2.34vw;
  padding: 1.92vh 1.875vw;
}

.modal-description-boxes-desc {
  margin-top: auto;
  color: #1a202c;
}

.modal-button-group {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  padding: 1.92vh 4.61vw;
  margin-left: 21.8vw;
}
