.onboard-steps-section {
  border-left: 5px solid #26890d;
  padding-left: 8px;
  margin-bottom: 16px;
}

.onboard-steps-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.onboard-steps-section-desc {
  width: 255px;
  height: 44px;
  color: #53565a;
}

.onboard-steps-section-count {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #26890d;
}

.onboard-steps-section-msg {
  color: #000000;
}
