.report-first-content {
  padding: 2.75vh 3.3vw;
  display: flex;
  flex-direction: column;
  gap: 0.78vw;
}

.breadcrum-div {
  margin-left: 1.17vw;
  display: flex;
  flex-direction: column;
  gap: 1.17vw;
}

.reports-button-section {
  display: flex;
  flex-direction: row;
  gap: 3.125vw;
}

.report-download-button {
  background: #26890d;
  display: flex;
}

.report-first-subheader {
  display: flex;
  gap: 0.78vw;
  width: 90%;
  align-items: center;
}

.report-first-subheader-button {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.78vw;
}

.report-first-main-section {
  border: 1px solid #d0d0ce;
  padding: 2.9vw;
  display: flex;
  flex-wrap: wrap;
  gap: 3.67vw;
}

.green-outlined-btn {
  width: auto;
  height: 5.5vh;
  border: 1px solid #26890d;
  color: #26890d;
  background-color: #fff;
}

.green-btn {
  width: 12vw;
  height: 5.5vh;
  border: none;
  color: #fff;
  background-color: #26890d;
}
.first-content-style{
  margin-left: 4vw;
  flex-direction: column;
  gap: 1vw;
}